import { router } from "@inertiajs/react";

type AccountRegisterSendData = Record<keyof AuthBase, string>;
type PasswordResetSendData = Record<keyof PasswordReset, string>;
type PasswordConfirmSendData = Record<keyof PasswordConfirm, string>;
type PasswordForgotSendData = Record<keyof PasswordForgot, string>;
type AccountLoginSendData = Record<keyof AccountLogin, string>;

export function accountRegister(body: AccountRegisterForm) {
  const sendBody: AccountRegisterSendData = {
    email: body.email,
    password: body.password,
  };
  router.post(route("register"), sendBody);
}

export function passwordReset(body: PasswordReset) {
  const sendBody: PasswordResetSendData = {
    token: body.token,
    email: body.email,
    password: body.password,
  };
  router.post(route("password.store"), sendBody);
}

export function passwordConfirm(body: PasswordConfirm) {
  const sendBody: PasswordConfirmSendData = {
    password: body.password,
  };
  router.post(route("password.confirm"), sendBody);
}

export function passwordForgot(body: PasswordForgot) {
  const sendBody: PasswordForgotSendData = {
    email: body.email,
  };
  router.post(route("password.email"), sendBody);
}

export function accountLogin(body: AccountLogin) {
  const sendBody: AccountLoginSendData = {
    email: body.email,
    password: body.password,
    remember: body.remember ? "true" : "false",
  };
  router.post(route("login"), sendBody);
}

export function verifyEmail() {
  router.post(route("verification.send"));
}
