import { PropsWithChildren } from "react";
import LogoTitle from "../molecules/LogoTitle";

export default function Guest({ children }: PropsWithChildren) {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 dark:bg-gray-800">
      <div className="mb-8">
        <LogoTitle />
      </div>
      <div className="w-full sm:max-w-md px-6 py-4 shadow-md overflow-hidden bg-white dark:bg-dots-lighter dark:bg-gray-900">
        {children}
      </div>
    </div>
  );
}
